import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { LoginFormInput } from '../form';

const ResetView = ({ onSubmit, t }) => (
  <div className="container full-height">
    <div className="row full-height">
      <div className="center-box p-xs">
        <div className="center-form col-xs-12 col-sm-4 col-sm-offset-4 form-horizontal">
          <h2 className="text-center m-md">{t('Enter new Password')}</h2>
          <p className="text-center">{t('Please enter a new password that is at least 8 characters in length')}</p>

          <form className="validate" noValidate>
            <Field label={t('New Password')} component={LoginFormInput} name="password" type="password" focus />

            <Field label={t('Confirm Password')} component={LoginFormInput} name="confirm" type="password" />
            <div className="form-group text-center">
              <div className="col-xs-12">
                <button id="btnSubmit" type="submit" className="btn btn-primary m-xs" onClick={onSubmit}>
                  {t('Change Password')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
);

ResetView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ResetView;
