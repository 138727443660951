import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Router } from '../../../../common/routes';
import { loginUser, loadValidatePasswordResetTokenData, loadPostResetData } from '../../../actions/UserActions';
import AuthView from '../../../components/auth/AuthView';
import { AuthWrapper } from '../../../components/styled';
import validate from '../../../validation/LoginValidation';

class AuthPage extends Component {
  static propTypes = {
    loginUser: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    validatePasswordResetTokenData: PropTypes.shape(),
    postResetData: PropTypes.shape(),
    loadValidatePasswordResetTokenData: PropTypes.func.isRequired,
    loadPostResetData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    validatePasswordResetTokenData: null,
    postResetData: null,
  };

  handleFormSubmit = (user) => {
    this.props.loginUser(user);
  };

  handleForgotPassword = () => {
    Router.pushRoute('auth/forgot');
  };

  handleSignUp = () => {
    Router.pushRoute('auth/signup');
  };

  render() {
    const { handleSubmit, t, validatePasswordResetTokenData, postResetData } = this.props;

    return (
      <AuthWrapper>
        <AuthView
          onSubmit={handleSubmit(this.handleFormSubmit)}
          onForgotPassword={this.handleForgotPassword}
          onSignUp={this.handleSignUp}
          validatePasswordResetTokenData={validatePasswordResetTokenData}
          postResetData={postResetData}
          t={t}
          onErrorClose={() => {
            this.props.loadValidatePasswordResetTokenData();
          }}
          onSuccessClose={() => {
            this.props.loadPostResetData();
          }}
        />
      </AuthWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user: { user, validatePasswordResetTokenData, postResetData },
    tokens: { accessToken, refreshToken },
  } = state;

  return {
    user,
    validatePasswordResetTokenData,
    postResetData,
    accessToken,
    refreshToken,
    validate,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loginUser,
      loadValidatePasswordResetTokenData,
      loadPostResetData,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'loginForm',
  })(AuthPage),
);
