import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Router } from '../../../../common/routes';
import { validateSignupActivateToken, postSignupActivate } from '../../../actions/UserActions';
import ActivateView from '../../../components/auth/ActivateView';
import { AuthWrapper } from '../../../components/styled';

class ActivatePage extends Component {
  static propTypes = {
    url: PropTypes.shape().isRequired,
    validateSignupActivateToken: PropTypes.func.isRequired,
    postSignupActivate: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    postSignupActivateData: PropTypes.shape(),
    validateSignupActivateTokenData: PropTypes.shape(),
  };

  static defaultProps = {
    postSignupActivateData: {},
    validateSignupActivateTokenData: {},
  };

  constructor(props) {
    super(props);
    const { url } = props;
    props.validateSignupActivateToken(url.query.token);
  }

  componentWillReceiveProps(nextProps) {
    // if token is invalid then also to redirect on signup page
    if (nextProps.validateSignupActivateTokenData && nextProps.validateSignupActivateTokenData.resultKey == 'error') {
      Router.pushRoute('auth/signup');
    }
    // if signup done successfully then we have id return from db and redirect user on interstial no accuount page
    if (nextProps.postSignupActivateData && nextProps.postSignupActivateData.id > 0) {
      Router.pushRoute('auth/login'); // temporary redirect on login page as we have auth login realted change inside octanise-app
    }
  }

  handleFormSubmit = (user) => {
    user.token = this.props.url.query.token;
    this.props.postSignupActivate(user);
  };

  handleCancel = () => {
    Router.pushRoute('auth/login');
    // window.location.href='/auth/login';
  };

  render() {
    const { handleSubmit, t, postSignupActivateData, validateSignupActivateTokenData } = this.props;

    return (
      <AuthWrapper>
        <ActivateView onSubmit={handleSubmit(this.handleFormSubmit)} onCancel={this.handleCancel} t={t} />
      </AuthWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user: { user, validateSignupActivateTokenData, postSignupActivateData },
  } = state;

  return {
    user,
    validateSignupActivateTokenData,
    postSignupActivateData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      validateSignupActivateToken,
      postSignupActivate,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'activateForm',
  })(ActivatePage),
);
