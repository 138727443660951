import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Router } from '../../../../common/routes';
import { postSignup } from '../../../actions/UserActions';
import SignupView from '../../../components/auth/SignupView';
import { AuthWrapper } from '../../../components/styled';

class SignupPage extends Component {
  static propTypes = {
    postSignup: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    signupForm: PropTypes.shape(),
  };

  static defaultProps = {
    postSignupData: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      isGoogleVerified: false,
      postSignupData: { resultKey: 'error', signupEmail: '' },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { postSignupData } = nextProps;
    if (postSignupData !== this.props.postSignupData) {
      this.setState({
        postSignupData,
      });

      if (
        nextProps.signupForm &&
        nextProps.signupForm.submitSucceeded &&
        nextProps.postSignupData &&
        nextProps.postSignupData.resultKey == 'success'
      ) {
        // && nextProps.signupForm.values.userName!="") {
        nextProps.signupForm.values.isVerified = false;
        nextProps.signupForm.values.userName = '';
        nextProps.signupForm.submitSucceeded = false;
        if (window.grecaptcha !== undefined && window.grecaptcha !== null) window.grecaptcha.reset();
      }
    }
  }

  handleFormSubmit = (user) => {
    user.isVerified = this.state.isGoogleVerified;
    this.props.postSignup(user);
    /* if(this.props.signupForm && this.props.signupForm.values &&  this.props.signupForm.values.userName)
    {
    } */
  };

  handleCancel = () => {
    Router.pushRoute('auth/login');
    // window.location.href='/auth/login';
  };

  handleOnLoadCallback = () => {};

  handleOnVerifyCallback = (response) => {
    if (response) {
      this.setState({
        isGoogleVerified: true,
      });
    }
  };

  handleMessageClose = () => {
    this.setState({
      postSignupData: { resultKey: 'error', signupEmail: '' },
    });
  };

  render() {
    const { handleSubmit, t } = this.props;
    const { postSignupData } = this.state;

    return (
      <AuthWrapper>
        <SignupView
          onSubmit={handleSubmit(this.handleFormSubmit)}
          onCancel={this.handleCancel}
          onLoadCallback={this.handleOnLoadCallback}
          onVerifyCallback={this.handleOnVerifyCallback}
          postSignupData={postSignupData}
          onMessageClose={this.handleMessageClose}
          t={t}
          // recaptchaInstance={this.props.recaptchaInstance}
        />
      </AuthWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user: { user, postSignupData },
    form,
  } = state;

  return {
    user,
    postSignupData,
    signupForm: form.signupForm,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postSignup,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'signupForm',
  })(SignupPage),
);
