import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { LoginFormInput } from '../form';
import { googleRecaptcha } from '../../../common/config';

const ForgotView = ({
  onSubmit,
  onCancel,
  onLoadCallback,
  onVerifyCallback,
  postForgotData,
  onMessageClose,
  t,
  recaptchaInstance,
}) => (
  <div className="container full-height">
    <div className="row full-height">
      <div className="center-box p-xs">
        <div className="center-form col-xs-12 col-sm-4 col-sm-offset-4 form-horizontal">
          <h2 className="text-center m-md">{t('Reset your Password')}</h2>
          <p className="text-center">
            {t('Enter your email address below and we will send you a link to reset your password')}
          </p>
          <form className="validate" noValidate>
            {postForgotData && postForgotData.resultKey === 'success' && (
              <div className="row">
                <div className="col-xs-12">
                  <div className="alert alert-info fade in">
                    <button
                      id="btnCloseMessage"
                      className="close"
                      type="button"
                      onClick={onMessageClose}
                      data-dismiss="alert"
                    >
                      <i className="fas fa-times-circle" />
                    </button>
                    <div>An e-mail has been sent with further instructions.</div>
                  </div>
                </div>
              </div>
            )}
            <Field label={t('Email')} component={LoginFormInput} name="userName" type="email" focus />
            <div className="form-group">
              <label className="col-xs-12 col-sm-4" />
              <div className="col-xs-12 col-sm-8">
                <ReCAPTCHA sitekey={googleRecaptcha.siteKey} onChange={onVerifyCallback} />
              </div>
            </div>

            <div className="form-group text-center">
              <button id="btnCancel" type="button" className="btn btn-secondary m-xs" onClick={onCancel}>
                {t('Cancel')}
              </button>

              <button id="btnSubmit" type="submit" className="btn btn-primary m-xs" onClick={onSubmit}>
                {t('Send Password Reset E-Mail')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
);

ForgotView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ForgotView;
