import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Router } from '../../../../common/routes';
import { postForgot } from '../../../actions/UserActions';
import ForgotView from '../../../components/auth/ForgotView';
import { AuthWrapper } from '../../../components/styled';

class ForgotPage extends Component {
  // create a variable to store the component instance
  // recaptchaInstance;
  static propTypes = {
    postForgot: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    // postForgotData: PropTypes.arrayOf(PropTypes.shape()),
    // postForgotData: PropTypes.shape(),
    forgotForm: PropTypes.shape(),
    // recaptchaInstance: PropTypes.any,
  };

  static defaultProps = {
    postForgotData: {},
    // recaptchaInstance:null
  };

  constructor(props) {
    super(props);
    this.state = {
      isGoogleVerified: false,
      postForgotData: { resultKey: 'error' },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { postForgotData } = nextProps;
    if (postForgotData !== this.props.postForgotData) {
      this.setState({ postForgotData });

      if (
        nextProps.forgotForm &&
        nextProps.forgotForm.submitSucceeded &&
        nextProps.postForgotData &&
        nextProps.postForgotData.resultKey == 'success'
      ) {
        nextProps.forgotForm.values.isVerified = false;
        nextProps.forgotForm.values.userName = '';
        nextProps.forgotForm.submitSucceeded = false;
        if (window.grecaptcha !== undefined && window.grecaptcha !== null) window.grecaptcha.reset();
      }
    }
  }

  handleFormSubmit = (user) => {
    user.isVerified = this.state.isGoogleVerified;
    this.props.postForgot(user);
  };

  handleCancel = () => {
    Router.pushRoute('auth/login');
  };

  handleOnLoadCallback = () => {};

  handleOnVerifyCallback = (response) => {
    if (response) {
      this.setState({
        isGoogleVerified: true,
      });
    }
  };

  handleMessageClose = () => {
    this.setState({
      postForgotData: { resultKey: 'error' },
    });
  };

  render() {
    const { handleSubmit, t } = this.props;
    const { postForgotData } = this.state;

    return (
      <AuthWrapper>
        <ForgotView
          onSubmit={handleSubmit(this.handleFormSubmit)}
          onCancel={this.handleCancel}
          onLoadCallback={this.handleOnLoadCallback}
          onVerifyCallback={this.handleOnVerifyCallback}
          postForgotData={postForgotData}
          onMessageClose={this.handleMessageClose}
          t={t}
          // recaptchaInstance={this.props.recaptchaInstance}
        />
      </AuthWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user: { user, postForgotData },
    form,
  } = state;

  return {
    user,
    postForgotData,
    forgotForm: form.forgotForm,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postForgot,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'forgotForm',
  })(ForgotPage),
);
