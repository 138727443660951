import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { LoginFormInput } from '../form';

const ActivateView = ({ onSubmit, onCancel, t }) => (
  <div className="container full-height">
    <div className="row full-height">
      <div className="center-box p-xs">
        <div className="center-form col-xs-12 col-sm-4 col-sm-offset-4 form-horizontal">
          <h2 className="text-center m-md">{t('Your Details')}</h2>
          <p className="text-center">{t('Your e-mail address has been activated, please enter your details')}</p>

          <form className="validate" noValidate>
            <Field label={t('First Name')} component={LoginFormInput} name="firstName" type="text" focus />
            <Field label={t('Last Name')} component={LoginFormInput} name="lastName" type="text" />
            <Field label={t('New Password')} component={LoginFormInput} name="password" type="password" />

            <Field label={t('Confirm Password')} component={LoginFormInput} name="confirm" type="password" />
            <div className="form-group text-center">
              <div className="col-xs-12">
                <button id="btnCancel" type="button" className="btn btn-secondary m-xs" onClick={onCancel}>
                  {t('Cancel')}
                </button>
                &nbsp;
                <button id="btnSubmit" type="submit" className="btn btn-primary m-xs" onClick={onSubmit}>
                  {t('Save')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
);

ActivateView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ActivateView;
