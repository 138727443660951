import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { Router } from '../../../../common/routes';
import { validateResetToken, postReset } from '../../../actions/UserActions';
import ResetView from '../../../components/auth/ResetView';
import { AuthWrapper } from '../../../components/styled';

class ResetPage extends Component {
  static propTypes = {
    router: PropTypes.shape().isRequired,
    validateResetToken: PropTypes.func.isRequired,
    postReset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    postResetData: PropTypes.shape(),
    validatePasswordResetTokenData: PropTypes.shape(),
  };

  static defaultProps = {
    postResetData: {},
    validatePasswordResetTokenData: {},
  };

  constructor(props) {
    super(props);
    const { router } = props;
    props.validateResetToken(router.query.token);
  }

  componentWillReceiveProps(nextProps) {
    // if token is invalid then also to redirect on login
    if (nextProps.validatePasswordResetTokenData && nextProps.validatePasswordResetTokenData.resultKey === 'error') {
      Router.pushRoute('auth/login');
      // if password changed successfully then also to redirect on login
    } else if (nextProps.postResetData && nextProps.postResetData.resultKey === 'success') {
      Router.pushRoute('auth/login');
    }
  }

  handleFormSubmit = (user) => {
    user.token = this.props.router.query.token;
    this.props.postReset(user);
  };

  render() {
    const { handleSubmit, t } = this.props;

    return (
      <AuthWrapper>
        <ResetView onSubmit={handleSubmit(this.handleFormSubmit)} t={t} />
      </AuthWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user: { user, validatePasswordResetTokenData, postResetData },
  } = state;

  return {
    user,
    validatePasswordResetTokenData,
    postResetData,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      validateResetToken,
      postReset,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'resetForm',
  })(ResetPage),
);
